@tailwind base;
@tailwind components;
@tailwind utilities;

#root,
body,
html {
  height: 100%;
  @apply bg-slate-950;
}

@keyframes moveIt {
  to {
    background-position: 800px 0;
  }
}
